import React from 'react';

const Plug = () => {
    return (
        <div className="plug-container">
            <h2 className="plug-header">
                Under Development
            </h2>
            <p className="plug-description">
                We are working now to make this plugin available again. Until then,
        we’re looking forward to seeing you on our{" "}
                <a href="https://duecode.io/">
                    duecode.io
                </a>{" "}
        website with more functionality to track and address technical debt.
            </p>
        </div>
    )
}

export default Plug;