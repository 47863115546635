import React from "react";

import Plug from './components/plug';

import './styles/main.scss';

const App = () => {
  return (
    <div>
      <Plug />
    </div>
  );
};

export default App;
